import React, { Suspense } from 'react';
import { useEffect } from 'react';
import './App.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

// importing guards
import {checkAuth, checkLogin} from './Core/guards/protected-route';
import LoadingSpinner from './Shared/LoaderSpinner/LoadingSpinner';

// importing Routes

// import DefaultLayout from './Layouts/DefaultLayout';
// import Home from './Pages/Public/HomePage/Home';
// import DashboardLayout from './Layouts/DashboardLayout';
// import Contact from './Pages/Public/ContactPage/Contact';
// import Login from './Pages/Public/LoginPage/Login';
// import AccueilManager from './Pages/Private/AccueilManager';
// import Societe from './Pages/Public/NotreSociete/Societe';
// import SocieteManager from './Pages/Private/SocieteManager/SocieteManager';
// import Estimer from './Pages/Public/EstimerPage/Estimer';
// import ConfirmPage from './Pages/Public/EstimerPage/Components/ConfirmPage';
// import NonEligible from './Pages/Public/EstimerPage/Components/NonEligible';
// import MesBesoins from './Pages/Public/MesBesoins/MesBesoins';
// import LesAides from './Pages/Public/LesAides/LesAides';
// import ManagMesBesoins from './Pages/Private/ManagMesBesoins/ManagMesBesoins';
// import NotreService from './Pages/Public/ServicePage/NotreService';
// import PDC from './Pages/Public/PDC/pdc';
// import PDG from './Pages/Public/PDG/pdg';
// import Mentionslegales from './Pages/Public/MentionsLegales/mentionslegales';
// import ManagEstimer from './Pages/Private/ManagEstimerAides/ManagEstimer';
// import ManagLesAides from './Pages/Private/components/ManagLesAides/ManagLesAides';
// import NosAgences from './Pages/Public/NosAgences/NosAgences';
// import Sousbesoins from './Pages/Public/MesBesoins/SousBesoins/Sousbesoins';
// import ManagNosAgences from './Pages/Private/NosAgeneces/ManagNosAgences';
// import ManagContact from './Pages/Private/MangContact/ManagContact';
// import ManagSousBesoins from './Pages/Private/ManagSousBesoins/ManagSousBesoins';
// import ManagServices from './Pages/Private/ManagServices/ManagServices';
// import ContactList from './Pages/Private/ContactList/ContactList';
// import EstimerList from './Pages/Private/EstimerList/EstimerList';
// import ManagPDGC from './Pages/Private/ManagPDGC/ManagPDGC';
// import ManagPDC from './Pages/Private/ManagPDC/ManagPDC';
// import ManagML from './Pages/Private/ManagML/ManagML';

const DefaultLayout = React.lazy(() => import('./Layouts/DefaultLayout'));
const DashboardLayout = React.lazy(() => import('./Layouts/DashboardLayout'));
const Home = React.lazy(() => import('./Pages/Public/HomePage/Home'));
const Contact = React.lazy(() => import('./Pages/Public/ContactPage/Contact'));
const Login = React.lazy(() => import('./Pages/Public/LoginPage/Login'));
const AccueilManager = React.lazy(() => import('./Pages/Private/AccueilManager'));
const Societe = React.lazy(() => import('./Pages/Public/NotreSociete/Societe'));
const SocieteManager = React.lazy(() => import('./Pages/Private/SocieteManager/SocieteManager'));
const Estimer = React.lazy(() => import('./Pages/Public/EstimerPage/Estimer'));
const ConfirmPage = React.lazy(() => import('./Pages/Public/EstimerPage/Components/ConfirmPage'));
const NonEligible = React.lazy(() => import('./Pages/Public/EstimerPage/Components/NonEligible'));
const MesBesoins = React.lazy(() => import('./Pages/Public/MesBesoins/MesBesoins'));
const LesAides = React.lazy(() => import('./Pages/Public/LesAides/LesAides'));
const ManagMesBesoins = React.lazy(() => import('./Pages/Private/ManagMesBesoins/ManagMesBesoins'));
const NotreService = React.lazy(() => import('./Pages/Public/ServicePage/NotreService'));
const PDC = React.lazy(() => import('./Pages/Public/PDC/pdc'));
const PDG = React.lazy(() => import('./Pages/Public/PDG/pdg'));
const Mentionslegales = React.lazy(() => import('./Pages/Public/MentionsLegales/mentionslegales'));
const ManagEstimer = React.lazy(() => import('./Pages/Private/ManagEstimerAides/ManagEstimer'));
const ManagLesAides = React.lazy(() => import('./Pages/Private/components/ManagLesAides/ManagLesAides'));
const NosAgences = React.lazy(() => import('./Pages/Public/NosAgences/NosAgences'));
const Sousbesoins = React.lazy(() => import('./Pages/Public/MesBesoins/SousBesoins/Sousbesoins'));
const ManagNosAgences = React.lazy(() => import('./Pages/Private/NosAgeneces/ManagNosAgences'));
const ManagContact = React.lazy(() => import('./Pages/Private/MangContact/ManagContact'));
const ManagSousBesoins = React.lazy(() => import('./Pages/Private/ManagSousBesoins/ManagSousBesoins'));
const ManagServices = React.lazy(() => import('./Pages/Private/ManagServices/ManagServices'));
const ContactList = React.lazy(() => import('./Pages/Private/ContactList/ContactList'));
const EstimerList = React.lazy(() => import('./Pages/Private/EstimerList/EstimerList'));
const ManagPDGC = React.lazy(() => import('./Pages/Private/ManagPDGC/ManagPDGC'));
const ManagPDC = React.lazy(() => import('./Pages/Private/ManagPDC/ManagPDC'));
const ManagML = React.lazy(() => import('./Pages/Private/ManagML/ManagML')); 

function App() {

  useEffect(() => {
    AOS.init();
  }, []);


  const router = createBrowserRouter([
    {
      path:"/",
      element: <DefaultLayout />,
      children: [
        { index: true, 
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <Home /> 
          </Suspense>
        },
        {
          path:'contact',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <Contact />
          </Suspense>
        },
        {
          path:'societe',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <Societe />
          </Suspense>
        },
        {
          path:'estimer',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <Estimer />
          </Suspense>
        },
        {
          path:'confirmepage',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ConfirmPage />
          </Suspense>
        },
        {
          path:'noneligible',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <NonEligible />
          </Suspense>
        },
        {
          path:'mesbesoins',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <MesBesoins />
          </Suspense>
        },
        {
          path:'aides',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <LesAides />
          </Suspense>
        },
        {
          path:'services',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <NotreService />
          </Suspense>
        },
        {
          path:'pdc',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <PDC />
          </Suspense>
        },
        {
          path:'pdgc',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <PDG />
          </Suspense>
        },
        {
          path:'mlegales',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <Mentionslegales />
          </Suspense>
        },
        {
          path:'nosagences',
          element:  
          <Suspense fallback={<LoadingSpinner />}>
            <NosAgences />
          </Suspense>
        },
        {
          path:'mesbesoins/:besoinid',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <Sousbesoins />
          </Suspense>
        }
      ],
    },
    {
      path: "/login",
      element: 
      <Suspense fallback={<LoadingSpinner />}>
        <Login />
      </Suspense>,
      loader: checkLogin
    },
    {
      path: "/admin",
      element:
      <Suspense fallback={<LoadingSpinner />}>
        <DashboardLayout />
      </Suspense>,
      loader: checkAuth,

      children: [
        {
          path: 'managhome',
          element:
          <Suspense fallback={<LoadingSpinner />}>
            <AccueilManager />
          </Suspense>
          
        },
        {
          path: 'managsociete',
          element: <SocieteManager />
        },
        {
          path: 'managmesbesoins',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagMesBesoins />
          </Suspense>
        },
        {
          path: 'managEstimer',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagEstimer />
          </Suspense>
        },
        {
          path: 'ManagLesAides',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagLesAides />
          </Suspense>
        },
        {
          path: 'managNosAgences',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagNosAgences />
          </Suspense>
        },
        {
          path:'managContact',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagContact />
          </Suspense>
        },
        {
          path:'managSousBesoins',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagSousBesoins />
          </Suspense>
        },
        {
          path: 'managServices',
          element:
          <Suspense fallback={<LoadingSpinner />}>
            <ManagServices />
          </Suspense>
        },
        {
          path: 'ContactList',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ContactList />
          </Suspense>
        },
        {
          path: 'EstimerList',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <EstimerList />
          </Suspense>
        },
        {
          path: 'ManagPDGC',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagPDGC />
          </Suspense>
        },
        {
          path: 'ManagPDC',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagPDC />
          </Suspense>
        },
        {
          path: 'ManagMl',
          element: 
          <Suspense fallback={<LoadingSpinner />}>
            <ManagML />
          </Suspense>
        },
      ],
    }

  ]);

  return (
    <div className="App">
         <RouterProvider router={router}/>
    </div>
  );
}

export default App;

