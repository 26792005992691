import React from "react";
import "./Spinner.scss";
import LoaderIcon from "../../assets/icons/GELoderIcon.svg"

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <img src={LoaderIcon} alt="logo GE"/>
    </div>
  );
}