import { createSlice } from "@reduxjs/toolkit";

export const formSlice = createSlice({
    name: "formState",
    initialState: {fullname:'M.Nom', phone:'numéro de télephone'},
    reducers:{
        changeformState: (state, action) => {
            state.fullname = action.payload.fullname;
            state.phone = action.payload.phone;
        },
    }
});

export const { changeformState } = formSlice.actions;
export default formSlice.reducer;