import { createSlice } from "@reduxjs/toolkit";

export const spinnerSlice = createSlice({
    name: "spinnerState",
    initialState: {status:false},
    reducers:{
        changeSpinnerStatus: (state, action) => {
            state.status = action.payload;
        },
    }
});

export const { changeSpinnerStatus } = spinnerSlice.actions;
export default spinnerSlice.reducer;