import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp, faBars, faGaugeHigh, faPager, faArrowRightFromBracket, faPen, faUpload, faClose, faArrowUp, faLocationDot, faCheckDouble, faHouse, faBuilding, faStar, faDatabase, faBookAtlas, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { store } from './store';
import { Provider } from 'react-redux'

library.add(faChevronDown, faChevronUp, faBars, faGaugeHigh, faPager, 
  faArrowRightFromBracket, faPen, faUpload, faClose, faArrowUp, 
  faLocationDot, faCheckDouble, faHouse, faBuilding, faStar, faDatabase, faBookAtlas, faTrash, faPlus);
  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
