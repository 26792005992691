import { redirect } from "react-router-dom";

export function checkAuth() {
    const token = localStorage.getItem('token');
    if(token !== null && token !== undefined && token !== ''){
        return false;
    }else {
        return redirect('/login');
    }
}

export function checkLogin() {
    const token = localStorage.getItem('token');
    if(token !== null && token !== undefined && token !== ''){
        return redirect('/admin/managhome');
    }else {
        return false;
    }
}